import aedutec from './aedutec';
import avamec from './avamec';
import avaviitra from './avaviitra';
import conectamaisedu from './conectamaisedu';
import devavaviitra from './devavaviitra';
import espiral from './espiral';
import estartec from './estartec';
import invar from './invar';
import masteradam from './masteradam';
import microkids from './microkids';
import movimenta from './movimenta';
import munera from './munera';
import prefacio from './prefacio';
import redudigital from './redudigital';
import localhost from './redudigital';
import saberes from './saberes';
import sebraeedu from './sebraeedu';
import siao from './siao';

const themes = {
  'aedutec.redu.digital': aedutec,
  'www.aedutec.redu.digital': aedutec,
  'avamecinterativo.mec.gov.br': avamec,
  'www.avamecinterativo.mec.gov.br': avamec,
  'beta.ava.viitra.in': redudigital,
  'www.beta.ava.viitra.in': redudigital,
  'ava.conectamaisedu.com.br': conectamaisedu,
  'www.ava.conectamaisedu.com.br': conectamaisedu,
  'digital.editoraespiral.com.br': espiral,
  'www.digital.editoraespiral.com.br': espiral,
  'sava.estartec.net': estartec,
  'www.sava.estartec.net': estartec,
  'adams.mastersolucoesedu.com.br': masteradam,
  'www.adams.mastersolucoesedu.com.br': masteradam,
  'digital.microkids.com.br': microkids,
  'www.digital.microkids.com.br': microkids,
  'comunidade.movimentaeducacao.com.br': movimenta,
  'www.comunidade.movimentaeducacao.com.br': movimenta,
  'digital.prefacioeditora.com.br': prefacio,
  'www.digital.prefacioeditora.com.br': prefacio,
  'redu.digital': redudigital,
  'www.redu.digital': redudigital,
  'saberes.edicoesipdh.com.br': saberes,
  'www.saberes.edicoesipdh.com.br': saberes,
  'sebraeedu.com.br': sebraeedu,
  'www.sebraeedu.com.br': sebraeedu,
  'educa.siao.seg.br': siao,
  'www.educa.siao.seg.br': siao,
  'ava.editoramunera.com.br': munera,
  'www.ava.editoramunera.com.br': munera,
  'dev.viitra.in': devavaviitra,
  'www.dev.viitra.in': devavaviitra,
  'invar.redu.digital': invar,
  'www.invar.redu.digital': invar,
  localhost
};

export default themes[window.location.hostname];
